/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { ACTION_TYPES, getPriceStr } from "./utils"
 import parse from "html-react-parser"
 export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if ( _text ) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
} 
 const IntroInternationCopy = ({ description, lang, meta, title, searchParams, location, hitscount }) => {
 
   const {areas, bedrooms, pType, type, price } = searchParams;
 
   const actionStr = ACTION_TYPES[pType];
   const priceStr = getPriceStr(price)
  //  const bedRooms = searchParams.bedrooms
 //console.log("searchParams",searchParams)
   let propertyType = "" 
   
   const capitalize = (s) => {
     if (typeof s !== 'string') return ''
     return s.charAt(0).toUpperCase() + s.slice(1)
   }
 
   let desc = "";
   desc = "For finding your dream new home effortlessly, you can filter and sort results. If you have any questions about your purchase, reach out to your local agents at Bentley Hurst."  
 
   return (
    <p className="intro-text">
        {parse(desc)}
        {/* Browse through our collection of stunning {h1}. Viewings for properties can be booked easily on our website. If you would like any property advice, please <Link to={'/contact-manchester-estate-agents'}>contact Bentley Hurst Estate agents</Link>. */}
    </p>     
   )
 }
 
 IntroInternationCopy.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
   title: ``,
   searchParams: [],
 }
 
 IntroInternationCopy.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   searchParams: PropTypes.any,
 }
 
 export default IntroInternationCopy
 
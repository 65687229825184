import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils"
import { Button, Form, Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import Select from 'react-select';
import Header from "@Components/Header/Header";
import Footer from "@Components/Footer/Footer";
import GetStartCard from '@Components/SearchResult/GetStartCard/GetStartCard'
import SoldFilter from "@Components/SearchResult/soldFilter"
import GardenFilter from "@Components/SearchResult/filterMustHaveGarden"
import ParkingFilter from "@Components/SearchResult/filterMustHaveParking"
import { Helmet } from "react-helmet";
import NoResultPage from "../../Components/NoResultPage";
import { isSold } from '@Components/common/site/utils';
// My account
import { UserObjectStoreProvider, useAuthState, getUserobjectData, SaveItem, savedSearchParams } from "@starberry/myaccount-website-utils";

//
import $ from "jquery"
import qs from "qs"
import algoliasearch from "algoliasearch/lite"
import {
    connectStats,
    SortBy,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    // Menu,
    connectMenu,
    connectRange,
    Configure,
    // connectSortBy,
    ToggleRefinement,
    connectToggleRefinement
} from "react-instantsearch-dom"
import {
    GoogleMapsLoader,
    GeoSearch,
    // Marker,
    CustomMarker
} from 'react-instantsearch-dom-maps'
import Cookies from 'universal-cookie'
import { navigate } from "@reach/router"
import { isMobile } from "react-device-detect";
import MapResultsDesktop from '@Components/PropertySearch/mapview-desktop';
import { ShowProcessedImage } from '@Components/common/ggfx-client/module/components/show-image';
import {
    parseSearchUrl,
    internationalPropertyH1
} from "@Components/PropertySearch/utils";
import SeoInternationalResults from "@Components/PropertySearch/seoInternationalResults"
import IntroInternationCopy from "@Components/PropertySearch/introInternationCopy"
import imageConfig from "../../../static/images/config.json"
import noImage from "../../images/no-image.png"
import './assets/styles/_index.scss'
var includeSold = false;
var parkVal = false;
var gardenVal = false;

const mycookies = new Cookies();
const updateAfter = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

const searchClient = algoliasearch(
    `${process.env.GATSBY_ALGOLIA_APP_ID}`,
    `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
)

const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`

var hitscount = 999999;

// Property results loop
const InfiniteHits = ({
    hits,
    hasPrevious,
    refinePrevious,
    hasMore,
    refineNext,
    location,
    userObjects
}) => {
    hitscount = hits.length;
    return (
        <>
            <>
                {(hits.length > 0 && hasPrevious) && (
                    <button
                        id="myprevbut"
                        onClick={refinePrevious}
                        disabled={!hasPrevious}
                        className="d-none"
                    >
                        Show prev
                    </button>
                )}
            </>

            {

                hits.map((hit, i) => {

                    // property details url structure
                    let uriStr = "international-property/"; 
                    // property details url structure

                    //property description
                    let text_truncate = function (str, length, ending) {
                        // 
                        var $win = $(window);

                        if ($win.width() <= 1500) {
                            if (length == null) {
                                length = 100;
                            }
                        } else if ($win.width() >= 1500) {
                            if (length == null) {
                                length = 200;
                            }
                        }
                        // 

                        if (ending == null) {
                            ending = '...';
                        }
                        if (str.length > length) {
                            return str.substring(0, length - ending.length) + ending;
                        } else {
                            return str;
                        }
                    };
                    // property description

                    // Book a viewing
                    const bookViewing = (e, hit) => {
                        const pageurl = window.location.hostname + "/" + uriStr + "-" + hit.slug + "-" + hit.objectID
                        sessionStorage.setItem('data', JSON.stringify(hit))
                        sessionStorage.setItem('property_url', pageurl)
                    }
                    // Book a viewing

                    // console.log ("wwwww", hit.images[0])

                    return (
                        <>
                            <UserObjectStoreProvider>
                                <div className="property-card d-md-flex flex-wrap mysingleproperty" key={i}
                                    id={`mySaleProp${hit.objectID}`}
                                    data-objectid={hit.objectID} data-lat={hit?._geoloc?.lat} data-lng={hit?._geoloc?.lng}
                                >
                                    <div className="view-part1">
                                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                            <div className="main-item">
                                                {
                                                    hit.images && (hit.images.length > 0) ?
                                                        <div className="img-zoom">
                                                            {hit.status === "Sold STC" ? <div className="sold-cls">Sold</div> : hit.status === "Sold" ? <div className="sold-cls">Sold</div> : null}
                                                            <picture>
                                                                {/* <source media="(min-width:768px)" srcset={mainImg01Large} /> */}
                                                                <Link id={`mySaleProp${hit.objectID}`}
                                                                    onClick={() => mycookies.set('mySaleProp', hit.objectID, { path: '/', maxAge: 300 })}
                                                                    to={`/${uriStr}${hit.slug}-${hit.objectID}`}
                                                                    state={{ referrer: location.pathname }}>
                                                                    <ShowProcessedImage images={hit.images[0]} attr={{ className: 'propery-img', alt: '' }} transforms={imageConfig.property.searchResults.sizes} />
                                                                </Link>
                                                            </picture>
                                                        </div>
                                                        :
                                                        <img src={noImage} alt="" />
                                                }
                                                {/* <div className="on-map">
                                            { (hit.images ? ((hit.images[0]['730x492']) ? <FloorPlanModalDetails floorplanimg={hit.images[0]['730x492']} icon={'true'} image_count={''} /> : ''): '') }
                                            <Link to="#"><i className="icon-play-btn"></i> <span> 1</span></Link>
                                        </div> */}
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                    <div className="card-details">
                                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                            <Link id={`mySaleProp${hit.objectID}`}
                                                onClick={() => mycookies.set('mySaleProp', hit.objectID, { path: '/', maxAge: 300 })}
                                                to={`/${uriStr}${hit.slug}-${hit.objectID}`}
                                                state={{ referrer: location.pathname }}>
                                                <div className="detail-head d-flex align-items-center justify-content-between">
                                                    {hit.price ? <p className="price">&pound;{hit.price.toLocaleString()}</p> : ''}
                                                    {hit.department != "international" &&
                                                      <SaveItem pid={hit.objectID} type="property" userObjects={userObjects}>
                                                            <Link className="wishlist-icn" to="#"><i className="icon-heart"></i></Link>
                                                        </SaveItem>
                                                    }
                                                  
                                                </div>
                                                {hit.title ? <span className="prop-title d-block"><Link id={`mySaleProp${hit.objectID}`}
                                                    onClick={() => mycookies.set('mySaleProp', hit.objectID, { path: '/', maxAge: 300 })}
                                                    to={`/${uriStr}${hit.slug}-${hit.objectID}`}
                                                    state={{ referrer: location.pathname }}>{hit.title}</Link></span> : ''}
                                                {hit.display_address ? <span className="address-title d-block">{hit.display_address}</span> : ''}
                                                {hit.description ? <p className="prop-desc">{text_truncate(hit.description)}</p> : ''}
                                            </Link>
                                            <ul className="property-icons list-inline">
                                                {!isSold(hit.status) && (
                                                    <li className="list-inline-item">
                                                        <Link to="/book-a-viewing" onClick={(e) => bookViewing(e, hit)} className="distance d-flex align-items-center"><i className="icon-date"></i> Book a viewing</Link>
                                                    </li>
                                                )}
                                                {
                                                    hit.bedroom ?
                                                        <li className="list-inline-item">
                                                            <span className="d-flex align-items-center property-bedwrap">
                                                                <i className="bedroom-icon"></i> <span className="property-bedbath pl-3">{hit.bedroom} {hit.bedroom > 1 ? 'Bedrooms' : 'Bedroom'}</span>
                                                            </span>
                                                        </li>
                                                        : ''
                                                }
                                                {
                                                    hit.bathroom ?
                                                        <li className="list-inline-item">
                                                            <span className="d-flex align-items-center property-bedwrap">
                                                                <i className="bathroom-icon"></i> <span className="property-bedbath pl-3">{hit.bathroom} {hit.bathroom > 1 ? 'Bathrooms' : 'Bathroom'}</span>
                                                            </span>
                                                        </li>
                                                        : ''
                                                }
                                                {
                                                    hit.reception ?
                                                        <li className="list-inline-item">
                                                            <span className="d-flex align-items-center property-bedwrap">
                                                                <i className="reception-icon"></i> <span className="property-bedbath pl-3">{hit.reception} {hit.reception > 1 ? 'Receptions' : 'Reception'}</span>
                                                            </span>
                                                        </li>
                                                        : ''
                                                }
                                            </ul>
                                            {/* <div className="d-lg-flex justify-content-between property-icons">
                                        <Link to="/book-a-viewing" onClick={(e) =>bookViewing(e, hit)} className="distance d-flex align-items-center"><i className="icon-date"></i> Book a viewing</Link>
                                        <span className="d-flex align-items-center property-bedwrap">
                                            <i className="bedroom-icon"></i> <span className="property-bedbath pl-3">1 Bedroom</span>
                                        </span>
                                        <span className="d-flex align-items-center property-bedwrap">
                                            <i className="bathroom-icon"></i> <span className="property-bedbath pl-3">1 Bathroom</span>
                                        </span>
                                        <span className="d-flex align-items-center property-bedwrap">
                                            <i className="reception-icon"></i> <span className="property-bedbath pl-3">1 Reception</span>
                                        </span>
                                    </div> */}
                                        </ScrollAnimation>
                                    </div>
                                </div>

                                {
                                    ((i + 1) % 10) === 0 ?
                                        <GetStartCard />
                                        : ''
                                }
                            </UserObjectStoreProvider>
                        </>
                    )
                })
            }

            <Container>
                <Row>
                    <Col lg={12} className="mb-5 text-center">
                        {(hits.length > 0 && hasMore) && (
                            <button
                                id="mynextbut"
                                onClick={refineNext}
                                disabled={!hasMore}
                                className="btn btn-primary"
                            >
                                Load more
                            </button>
                        )}
                    </Col>
                </Row>
            </Container>
        </>

    )
}



const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)
// Property results loop

// Property No Results
const NoStats = ({ processingTimeMS, nbHits, location, status, department, OpenMember }) => {



    return (
        <>
            {nbHits === 0 &&
                <Row>
                    <Col lg={12} className="text-center mb-5 no-result">
                        {/* <div>Sorry, we could find properties that match your criteria right now. Try again by refining your search. Also, here are a few of our other properties that you can check out.</div> */}

                        <NoResultPage uriStr={location} status={status} department={department} OpenMember={OpenMember} />
                    </Col>
                </Row>
            }
        </>
    )
}

const CustomNoStats = connectStats(NoStats)
// Property No Results

// Property Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Property Results count

// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
    return (
        <Form.Group>
            <Form.Control type="text" className="postcode_input" placeholder="Street, area or postcode" onChange={event => refine(event.currentTarget.value.replace(/ /g, "-").toLowerCase())} />
        </Form.Group>
    )
})
// Searchbox

// Dropdown react select styles
const styles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#1A2440" : null,
            color: isFocused ? "#ffffff" : "#555965"
        };
    },
    control: styles => ({
        ...styles,
        backgroundColor: null,
        border: 0,
        outline: 0,
        boxShadow: 'none',
        color: '#555965',
        fontSize: '0.875rem',
        padding: '10',
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#555965',
        }
    },
    valueContainer: (styles) => ({
        ...styles,
        fontSize: '0.875rem',
        padding: 10,
    })
}
// Dropdown react select styles

// Property Min price
const priceminRange = [
    {
        value: "0",
        label: "Min Price"
    },
    {
        value: "50000",
        label: "£50,000",
    },
    {
        value: "100000",
        label: "£100,000",
    },
    {
        value: "500000",
        label: "£500,000",
    },
    {
        value: "600000",
        label: "£600,000",
    },
    {
        value: "700000",
        label: "£700,000",
    },
    {
        value: "800000",
        label: "£800,000",
    },
    {
        value: "900000",
        label: "£900,000",
    },
    {
        value: "1000000",
        label: "£1,000,000",
    },
    {
        value: "2000000",
        label: "£2,000,000",
    },
    {
        value: "3000000",
        label: "£3,000,000",
    },
    {
        value: "4000000",
        label: "£4,000,000",
    },
    {
        value: "5000000",
        label: "£5,000,000",
    },
    {
        value: "7500000",
        label: "£7,500,000",
    },
    {
        value: "10000000",
        label: "£10,000,000",
    },
]

const minpriceSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {
    let minPriceRange = priceminRange
    if (currentRefinement.max !== undefined && currentRefinement.max !== "0") {
        minPriceRange = priceminRange.filter(
            x => parseInt(x.value) < parseInt(currentRefinement.max)
        )
    }
    return (
        <Form.Group>
            <div className="select-box select-box-custom">
                <Select
                    name="minprice"
                    options={minPriceRange}
                    defaultValue={currentRefinement.min || "0"}
                    placeholder="Min Price"
                    classNamePrefix={"select-opt"}
                    isSearchable={false}
                    onChange={(e) => {
                        minpriceval = e.value
                        if (e.value === "0") {
                            if (maxpriceval) {
                                refine({
                                    max: maxpriceval,
                                })
                            } else refine(e.value, e.label)
                        } else {
                            refine({
                                min: e.value,
                                max: maxpriceval,
                            })
                        }
                    }}
                    styles={styles}
                />
            </div>
        </Form.Group>
    )
}

const CustomminpriceSelectbox = connectRange(minpriceSelectbox)
// Property Min price

// Include sold and let
const ToggletttRefinement = ({
    currentRefinement,
    label,
    count,
    refine,
    createURL,
}) => {
    return (
        <a
            href={'javascripti:;'}
            onClick={event => {
                event.preventDefault();
                includeSold = !includeSold;
                refine(!currentRefinement);
            }}
        >
            <div class="ais-ToggleRefinement">
                <label class="ais-ToggleRefinement-label">
                    <input type="checkbox" class="ais-ToggleRefinement-checkbox" value="" id="flexCheckDefault" checked={includeSold ? "checked" : ""} />
                    <span class="ais-ToggleRefinement-labelText">{label}</span>
                </label>
            </div>
        </a>
    )
}

const CustomToggleRefinement = connectToggleRefinement(ToggletttRefinement);
// Include sold and let


// Park
const ToggletttRefinementPark = ({
    currentRefinement,
    label,
    count,
    refine,
    createURL,
}) => {
    return (
        <a
            href={'javascripti:;'}
            onClick={event => {
                event.preventDefault();
                parkVal = !parkVal;
                refine(!currentRefinement);
            }}
        >
            <div class="ais-ToggleRefinement">
                <label class="ais-ToggleRefinement-label">
                    <input type="checkbox" class="ais-ToggleRefinement-checkbox" value="" id="flexCheckDefault2" checked={parkVal ? "checked" : ""} />
                    <span class="ais-ToggleRefinement-labelText">{label}</span>
                </label>
            </div>
        </a>
    )
}

const CustomToggleRefinementPark = connectToggleRefinement(ToggletttRefinementPark);
// End Park

// garden
const ToggletttRefinementGarden = ({
    currentRefinement,
    label,
    count,
    refine,
    createURL,
}) => {
    return (
        <a
            href={'javascripti:;'}
            onClick={event => {
                event.preventDefault();
                gardenVal = !gardenVal;
                refine(!currentRefinement);
            }}
        >
            <div class="ais-ToggleRefinement">
                <label class="ais-ToggleRefinement-label">
                    <input type="checkbox" class="ais-ToggleRefinement-checkbox" value="" id="flexCheckDefault3" checked={gardenVal ? "checked" : ""} />
                    <span class="ais-ToggleRefinement-labelText">{label}</span>
                </label>
            </div>
        </a>
    )
}

const CustomToggleRefinementGarden = connectToggleRefinement(ToggletttRefinementGarden);
// End garden

// Property Max price
const pricemaxRange = [
    {
        value: "0",
        label: "Max Price"
    },
    {
        value: "500000",
        label: "£500,000",
    },
    {
        value: "600000",
        label: "£600,000",
    },
    {
        value: "700000",
        label: "£700,000",
    },
    {
        value: "800000",
        label: "£800,000",
    },
    {
        value: "900000",
        label: "£900,000",
    },
    {
        value: "1000000",
        label: "£1,000,000",
    },
    {
        value: "2000000",
        label: "£2,000,000",
    },
    {
        value: "3000000",
        label: "£3,000,000",
    },
    {
        value: "4000000",
        label: "£4,000,000",
    },
    {
        value: "5000000",
        label: "£5,000,000",
    },
    {
        value: "7500000",
        label: "£7,500,000",
    },
    {
        value: "10000000",
        label: "£10,000,000",
    },
    {
        value: "15000000",
        label: "£15,000,000",
    },
    {
        value: "25000000",
        label: "£25,000,000",
    },
    {
        value: "50000000",
        label: "£50,000,000",
    }
]

const maxpriceSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {
    let maxPriceRange = pricemaxRange
    let maxpricedisable = ""
    if (currentRefinement.min !== undefined) {
        maxPriceRange = pricemaxRange.filter(
            x => x.value === "0" || parseInt(x.value) > parseInt(currentRefinement.min)
        )
    }
    if (currentRefinement.min === "10000000") {
        maxpricedisable = "disabled"
    }
    return (
        <Form.Group>
            <div className="select-box select-box-custom">
                <Select
                    name="maxprice"
                    options={maxPriceRange}
                    defaultValue={currentRefinement.max || "0"}
                    placeholder="Max Price"
                    classNamePrefix={"select-opt"}
                    isSearchable={false}
                    onChange={(e) => {
                        maxpriceval = e.value
                        if (e.value === "0") {
                            if (minpriceval) {
                                refine({
                                    min: minpriceval,
                                })
                            } else refine(e.value, e.label)
                        } else {
                            refine({
                                min: minpriceval,
                                max: e.value,
                            })
                        }
                    }}
                    styles={styles}
                />
            </div>
        </Form.Group>
    )
}

const CustommaxpriceSelectbox = connectRange(maxpriceSelectbox)
// Property Max price

// Property Bedrooms
const bedroomSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {
    let bedroomvalselect = ""
    if (currentRefinement.max !== undefined) {
        bedroomvalselect = 0
    } else if (currentRefinement.min !== undefined) {
        bedroomvalselect = currentRefinement.min
    }
    return (
        <>
            <Form.Group>
                <div className="select-box select-box-custom select-custom-beds">
                    <Select
                        name="bedroom"
                        options={[
                            { value: "", label: "Beds" },
                            { value: '1', label: '1+' },
                            { value: '2', label: '2+' },
                            { value: '3', label: '3+' },
                            { value: '4', label: '4+' },
                            { value: '5', label: '5+' },
                            { value: '6', label: '6+' }
                        ]}
                        // styles={styles}
                        placeholder="Beds"
                        classNamePrefix={"select-opt"}
                        isSearchable={false}
                        onChange={(e) => {
                            if (e.value === "") {
                                refine(e.value, e.label)
                            } else if (e.value === "0") {
                                refine({
                                    max: e.value,
                                })
                            } else {
                                refine({
                                    min: e.value,
                                })
                            }
                        }}
                        styles={styles}
                    />
                </div>
            </Form.Group>
        </>
    )
}

const CustombedroomSelectbox = connectRange(bedroomSelectbox)
// Property Bedrooms

// Property Type Filter
const propertyTypeFilter = ({ items, currentRefinement, refine }) => (
    <div className="property-radio-btn">
        <ul className="d-flex flex-wrap">
            <li>
                <label className="radio-box">
                    <input type="radio" name="building" value="" onClick={event => refine(event.currentTarget.value)} /> <span className="radio-property-name">All Properties</span>
                    <span className="radio-mark"></span>
                </label>
            </li>
            <li>
                <label className="radio-box">
                    <input type="radio" name="building" value="apartment" onClick={event => refine(event.currentTarget.value)} /> <span className="radio-property-name">Apartment</span>
                    <span className="radio-mark"></span>
                </label>
            </li>
            <li>
                <label className="radio-box">
                    <input type="radio" name="building" value="house" onClick={event => refine(event.currentTarget.value)} /> <span className="radio-property-name">House</span>
                    <span className="radio-mark"></span>
                </label>
            </li>
            {/* <li>
                <label className="radio-box">
                    <input type="radio" name="building" value="property" onClick={event => refine(event.currentTarget.value)} /> <span className="radio-property-name">Property</span>
                    <span className="radio-mark"></span>
                </label>
            </li> */}
            <li>
                <label className="radio-box">
                    <input type="radio" name="building" value="townhouse" onClick={event => refine(event.currentTarget.value)} /> <span className="radio-property-name">Townhouse</span>
                    <span className="radio-mark"></span>
                </label>
            </li>
        </ul>
    </div>
)

const CustomPropertyMenu = connectMenu(propertyTypeFilter);
// Property Type Filter


// Maps
const MapResultsDevice = (() => {
    return (
        <>
            <div id="map" className="map">
                <GoogleMapsLoader apiKey={process.env.GATSBY_MAPS_API_KEY}>
                    {google => <MapResults1 google={google} />}
                </GoogleMapsLoader>
            </div>
        </>
    )
})
var InfoWindow;
var onClickMarker;
var html;

const MapResults1 = ((props) => {
    InfoWindow = new props.google.maps.InfoWindow();

    onClickMarker = ({ hit, marker }) => {

        if (InfoWindow.getMap()) {
            InfoWindow.close();
        }


        // property details url structure
        let uriStr = "";

        if (hit.searchType === "sales") {
            uriStr = 'property-for-sale/'
        }
        // property details url structure

        // available days
        let currFullDate = new Date();
        let currYear = currFullDate.getFullYear();
        let currMonth = currFullDate.getMonth() + 1;
        let currDate = currFullDate.getDate();
        let currDateCal = currYear + "-" + currMonth + "-" + currDate;
        let propertyDate = hit.AvailableFrom;
        let availableFrom = "";
        let date1 = new Date(propertyDate).getTime();
        let date2 = new Date(currDateCal).getTime();

        if (date1 > date2) {
            var timeDiff = Math.abs(date1 - date2);
            // days difference
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            availableFrom = diffDays;
        } else { }
        // available days

        // display address
        var propertyAddressFinal = ""

        if (hit.display_address === undefined) {
            propertyAddressFinal = hit.display_address;
        } else {
            var propertyAddress = hit.display_address;
            var propertySplit = propertyAddress.split(',');

            for (var i = 0; i < propertySplit.length; i++) {
                // Trim the excess whitespace.
                propertySplit[i] = propertySplit[i].replace(/^\s*/, "").replace(/\s*$/, "");
                // Add additional code here, such as:
                propertyAddressFinal = propertySplit[0];
            }
        }

        html = '<a href="/' + uriStr + hit.slug + '-' + hit.objectID + '/"><div className="property"><div className="slider"><img style="width:100%; height:auto;" src="' + hit.images1[0]["326x218"] + '" /></div><div className="content"><h5>' + hit.display_address + '</h5><p>' + hit.title + '</p><p className="price">£' + hit.price.toLocaleString() + '</p></div></div></a>';

        InfoWindow.setContent(html);

        InfoWindow.open(marker.getMap(), marker);
    };
    return (
        <>
            {isMobile &&
                <Configure
                    hitsPerPage={1000}
                />
            }
            <GeoSearch google={props.google} >
                {({ hits }) => (
                    <div>
                        {hits.map(hit => (
                            <>
                                <CustomMarker
                                    anchor={{ x: 0, y: -5 }}
                                    key={hit.objectID}
                                    position={hit._geoloc}
                                    hit={hit}
                                    className={"icon-property_map_marker map-marker-" + hit.objectID}
                                    onClick={({ marker }) => {
                                        onClickMarker({ hit, marker, });
                                    }}
                                />
                            </>
                        ))}
                    </div>
                )}
            </GeoSearch>
        </>
    )
})
// Maps

// Create URL
export const createURL = (props, state) => {
    let myarea = state.menu

    const isDefaultRoute =
        !state.query &&
        !state.SortBy &&
        state.page === 1 &&
        state.menu &&
        state.range &&
        !state.range.price &&
        !state.range.bedroom

    if (isDefaultRoute) {
        return ""
    }

    var areaPath = "" //"in-manchester/"
    var bedroomPath = ""
    var pricePath = ""
    var pagePath = ""
    var propertypath = ""
    var sortPath = ""

    if (state.query) {
        if (state.query) {
            areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
        }
    }

    const queryParameters = {}

    // Property path
    if (state.menu) {
        if (myarea["building"]) {
            propertypath = "type-" + myarea["building"] + "/"
        }
    }
    // Property path

    // Bedrooms & Price range path
    if (state.range) {
        // Bedrooms
        if (state.range.hasOwnProperty('bedroom') && state.range.bedroom.max === 0) {
            bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
        }
        if (state.range.bedroom) {
            if (state.range.bedroom.min) {
                bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
            }
        }

        // Price
        if (state.range.price) {
            if (state.range.price.min && state.range.price.max) {
                pricePath = "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
            } else if (state.range.price.max) {
                pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
            } else if (state.range.price.min) {
                pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
            }
        }
    }
    // Bedrooms & Price range path

    // Sort path
    if (state.sortBy) {
        if (state.sortBy === index_name + "_price_asc") {
            sortPath = "sortby-price-asc/";
        }
        if (state.sortBy === index_name) {
            sortPath = "";
        }
        if (state.sortBy === index_name + "_most_recent") {
            sortPath = "sortby-most-recent/";
        }
    }
    // Sort path

    // Page path
    if (state.page && state.page !== 1) {
        pagePath = `page-${state.page}/`
    }
    // Page path

    const queryString = qs.stringify(queryParameters, {
        addQueryPrefix: true,
        arrayFormat: "repeat",
    })

    let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${queryString}${pagePath}${sortPath}`

    if ("/" === myUrl.substr(myUrl.length - 1))
        myUrl = myUrl.substr(0, myUrl.length - 1)

    return `/international-properties/${myUrl}/`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState) =>
    searchState ? `${createURL(props, searchState)}` : ""

const urlToSearchState = location => {
    if (location) {
        var query = ""
        let pathUri_main = location.pathname.split("/international-properties")

        var areaVal = ""
        var bedVal = 11
        var minpriceVal = ""
        var maxpriceVal = ""
        var pageVal = ""
        var propertyval = ""
        var sortVal = ""
        var sortVal_filt = ""

        if (pathUri_main[1]) {
            // following could be regexp
            let pathUri = pathUri_main[1].split("/")

            //lets loop
            for (let vi = 1; vi <= pathUri.length; vi++) {
                // check for area
                if (typeof pathUri[vi] === "undefined") {
                    continue
                }

                // Property type
                if (pathUri[vi].indexOf("type-") >= 0) {
                    propertyval = pathUri[vi].replace("type-", "")
                }

                // Area
                if (pathUri[vi].indexOf("in-") >= 0) {
                    areaVal = pathUri[vi].replace("in-", "")
                }

                // Price
                if (
                    pathUri[vi].indexOf("between-") >= 0 ||
                    pathUri[vi].indexOf("over-") >= 0 ||
                    pathUri[vi].indexOf("under-") >= 0
                ) {
                    let priceFilt1 = pathUri[vi].split("over-")
                    if (priceFilt1[1]) {
                        minpriceVal = priceFilt1[1]
                    }
                    let priceFilt2 = pathUri[vi].split("under-")
                    if (priceFilt2[1]) {
                        maxpriceVal = priceFilt2[1]
                    }
                    let priceFilt3 = pathUri[vi].split("between-")
                    if (priceFilt3[1]) {
                        let priceFilt4 = priceFilt3[1].split("-and-")
                        minpriceVal = priceFilt4[0]
                        maxpriceVal = priceFilt4[1]
                    }
                }

                // Bedrooms
                if (pathUri[vi].indexOf("-and-more-") >= 0) {
                    bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
                }

                if (pathUri[vi].indexOf("studio") >= 0) {
                    // its studio bedroom
                    bedVal = 0
                }

                // Sort by
                if (pathUri[vi].indexOf("sortby-") >= 0) {
                    sortVal_filt = pathUri[vi].replace("sortby-", "")

                    if (sortVal_filt === "price-asc") {
                        sortVal = index_name + "_price_asc"
                    }

                    if (sortVal_filt === "price-desc") {
                        sortVal = index_name + "_price_desc"
                    }

                    if (sortVal_filt === "most-recent") {
                        sortVal = index_name + "_most_recent"
                    }
                }

                // Page
                if (pathUri[vi].indexOf("page") >= 0) {
                    pageVal = pathUri[vi].replace("page-", "")
                }
            }
        }
    }

    // Property type
    if (propertyval) {
        query += `&menu[building]=` + propertyval
    }

    // Area
    if (areaVal) {
        areaVal = areaVal.toLowerCase();
        if (areaVal == "manchester") query += `&query=`
        else query += `&query=` + areaVal
    }

    // Price
    if (minpriceVal) {
        query += `&range[price][min]=` + minpriceVal
    }

    if (maxpriceVal) {
        query += `&range[price][max]=` + maxpriceVal
    }

    // Bedrooms
    if (bedVal == 0) {
        query += `&range[bedroom][min]=` + 0
    } else if (bedVal < 10) {
        query += `&range[bedroom][min]=` + bedVal
    }

    // Page
    if (pageVal) {
        query += `&page=${pageVal}`
    }

    // Sort by
    if (sortVal) {
        query += `&sortBy=` + sortVal
    }

    return qs.parse(query)
}
// URL to searchstate

class PropertySalesList extends React.Component {

    constructor(props) {
        super(props);
    }

    //
    state = {
        searchState: urlToSearchState(this.props.location),
        includeSoldinside: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.setState({ searchState: urlToSearchState(this.props.location) })
        }
    }

    onSearchStateChange = searchState => {
        clearTimeout(this.debouncedSetState)
        this.debouncedSetState = setTimeout(() => {
            navigate(searchStateToUrl(this.props, searchState), searchState)
        }, updateAfter)
        this.setState({ searchState })
    }

    /*onSearchStateChange2 = (searchState) => {
        this.setState({ searchState })
    }*/
    // 
    includeSoldclick = e => {
        this.setState({
            includeSoldinside: !this.state.includeSoldinside
        })
    }

    // Menu open
    handlerClick = (e) => {
        $(".header-style").toggleClass("open-search-block");
    }
    // Menu open

    // More Filter
    showDropdown = (e) => {
        $(".dropdown-exp").toggleClass("show");
    }

    closeFilterDropdown = (e) => {
        $(".dropdown-exp").removeClass("show");
    }
    // More Filter

    // Filter properties
    filterProperties = (e) => {
        $(".filter-list").toggleClass("active")
    }
    // Fitler properties

    componentDidMount() {
        // load on top
        $(".wrapper").addClass("header-style-search")

        $(document).ready(function () {
            $("html, body").scrollTop(0);
        });
        // load on top

        // Property filter close
        $(".mobile-filter .form-group .select-box .select-opt__control").click(function () {
            $(".dropdown-exp").removeClass("show");
        });

        $(".mobile-filter .form-group .select-box").click(function () {
            $(".dropdown-exp").removeClass("show");
        });

        $(".mobile-filter .form-group .form-control").click(function () {
            $(".dropdown-exp").removeClass("show");
        });
        // Property filter close

        // Sold let filter
        // $( ".filtersearchsoldlet" ).insertAfter( ".defaultsoldfilter" );
        // Sold let filter

        // Must haves filter
        //$(".filtermusthavegarden").insertAfter(".defaultmusthavegarden");
        //$(".filtermusthaveparking").insertAfter(".defaultmusthaveparking");
        // Must haves filter
    }



    render() {
        const searchParams = parseSearchUrl("sales", this.props.location.pathname);
        const h1 = internationalPropertyH1(searchParams);
        const areaName = searchParams.areas;
        const pType = searchParams.pType;

        const isPageLoadBg = (typeof this.props.path === "undefined");
        const saveSearchParams = savedSearchParams(searchParams);

        // console.log("props => ", this.props?.userObjects)

        var defaultStatus = this.state.includeSoldinside ? ["For Sale", "New Instruction", "Sold", "Sold STC"] : ["For Sale", "New Instruction"];

        let mystatus = ''
        if (defaultStatus) {
            if (Array.isArray(defaultStatus)) {
                mystatus = '(status:"' + (defaultStatus).join('" OR status:"') + '")'
            } else {
                mystatus = '(status:"' + (defaultStatus) + '")'
            }
        }
        return (
            <>
                <UserObjectStoreProvider>
                    {!isPageLoadBg &&
                        <SeoInternationalResults title={h1} searchParams={searchParams} location={this.props.location} url={this.props.location.href} />
                    } 
               
                    <div className="wrapper header-style">
                        <Header searchpage="not-fixed" showMenu={'showMenu'} handlerClick={this.handlerClick} />

                        <div className="">
                            {/* <div className="filtersearchsoldlet">
                          <SoldFilter searchState={this.state.searchState}
                            searchClient={searchClient}
                            indexName={index_name}
                            createURL={createURL}
                            onSearchStateChange={this.onSearchStateChange2}
                          />
                        </div> 
                        <div className="filtermusthavegarden">
                            <GardenFilter searchState={this.state.searchState}
                                searchClient={searchClient}
                                indexName={index_name}
                                createURL={createURL}
                                onSearchStateChange={this.onSearchStateChange2}
                            />
                        </div>
                        <div className="filtermusthaveparking">
                            <ParkingFilter searchState={this.state.searchState}
                                searchClient={searchClient}
                                indexName={index_name}
                                createURL={createURL}
                                onSearchStateChange={this.onSearchStateChange2}
                            />
                        </div> */}
                            <InstantSearch
                                indexName={index_name}
                                searchClient={searchClient}
                                searchState={this.state.searchState}
                                onSearchStateChange={this.onSearchStateChange}
                                createURL={createURL}
                                routing="true"
                            >
                                <Configure
                                     filters={`publish:true AND department:international`} 
                                    hitsPerPage={12}
                                />
                                <div className="d-none">
                                    {/* <RefinementList
                                    attribute="searchType"
                                    defaultRefinement={["sales"]}
                                />
                                <RefinementList
                                    attribute="publish"
                                    defaultRefinement={["true"]}
                                />
                                <RefinementList
                                    attribute="status"
                                    defaultRefinement={["For Sale"]}
                                    className="status_for_sale"
                                />
                                <RefinementList
                                    attribute="status"
                                    defaultRefinement={["New Instruction"]}
                                    className="status_for_sale"
                                /> */}
                                </div>

                                <div className="result-section">
                                    <div className="list-view-wrap">
                                        <div className="breadcrumb-top text-center" >
                                            <Container fluid>
                                                <Row>
                                                    <Col lg={12}>

                                                        <Breadcrumb>
                                                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                                            <Breadcrumb.Item active>Spain - Costa Blanca</Breadcrumb.Item>
                                                        </Breadcrumb>

                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>

                                        <Container>
                                            <Row>
                                                <Col>
                                                    <div className="search-heading">
                                                        <h1>{h1}
                                                            {/* <span className="">(<CustomStats />)</span> */}
                                                        </h1>
                                                        <IntroInternationCopy title={h1} searchParams={searchParams} location={this.props.location} hitscount={hitscount} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>

                                        <Container>
                                            <Row>
                                                <Col>
                                                    <div className="filter-block">
                                                        <a href="javascript:void(0)" className="filter-property-btn btn btn-outline" onClick={this.filterProperties}>Filter Properties</a>
                                                        <div className="filter-list">
                                                            <Form action="javascript:void(0)" >
                                                                <ul className="mobile-filter">
                                                                    <li>
                                                                        <SearchBox />
                                                                    </li>
                                                                    {/* <li>
                                                                    <Form.Group>
                                                                        <Form.Control type="text" placeholder="Radius" />
                                                                    </Form.Group>
                                                                </li> */}
                                                                    <li>
                                                                        <CustomminpriceSelectbox attribute="price" />
                                                                    </li>
                                                                    <li>
                                                                        <CustommaxpriceSelectbox attribute="price" />
                                                                    </li>
                                                                    <li>
                                                                        <CustombedroomSelectbox attribute="bedroom" />
                                                                    </li>
                                                                    
                                                                </ul>
                                                                <div className="filter-input">
                                                                    <ul className="d-flex align-items-center mobile-sort-section">
                                                                        {/*<li>
                                                                            <div className="property-filter_check">
                                                                                 <ToggleRefinement
                                                                                attribute="status"
                                                                                label="Include Sold / SSTC"
                                                                                value={['Sold']}
                                                                                // defaultRefinement={['Sold']}
                                                                            /> */}
                                                                                {/* <CustomToggleRefinement
                                                                                attribute="status"
                                                                                label="Include Sold / SSTC"
                                                                                value={['Sold']}
                                                                                defaultRefinement={includeSold}
                                                                            /> */}
                                                                                {/* <div class="ais-ToggleRefinement">
                                                                                    <label class="ais-ToggleRefinement-label">
                                                                                        <input type="checkbox" class="ais-ToggleRefinement-checkbox" value="" id="flexCheckDefault"
                                                                                            checked={this.state.includeSoldinside ? "checked" : ""}
                                                                                            onChange={this.includeSoldclick}
                                                                                        />
                                                                                        <span class="ais-ToggleRefinement-labelText">Include Sold / SSTC</span>
                                                                                    </label>
                                                                                </div>  
                                                                            </div>
                                                                            <div className="d-none">
                                                                                {/* <ToggleRefinement
                                                                                attribute="status"
                                                                                label="Sold STC"
                                                                                value={['Sold STC']}
                                                                                // defaultRefinement={['Sold STC']}
                                                                            /> */}
                                                                                {/* <CustomToggleRefinement
                                                                                attribute="status"
                                                                                label="Sold STC"
                                                                                value={['Sold STC']}
                                                                                defaultRefinement={includeSold}
                                                                            /> 
                                                                            </div>
                                                                        </li>*/}
                                                                        <li className="d-flex align-items-center">
                                                                            {/* Sort by: <span>Most recent</span> */}
                                                                            Sort by: <SortBy
                                                                                defaultRefinement={index_name}
                                                                                className="search-sory-by"
                                                                                items={[
                                                                                    { value: index_name, label: "Highest Price First" },
                                                                                    { value: index_name + "_price_asc", label: "Lowest Price First" },
                                                                                    { value: index_name + "_most_recent", label: "Most Recent First" },
                                                                                ]}
                                                                            />
                                                                        </li>
                                                                        {/* <li>
                                                                            <SaveItem type="search" searchParams={saveSearchParams} userObjects={this.props?.userObjects}>
                                                                                <strong class="save-alert">Get alerts for new properties</strong>
                                                                                <strong class="remove-alert">Remove alert</strong>
                                                                            </SaveItem>
                                                                        </li> */}
                                                                    </ul>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>

                                        <Container>
                                            <Row>
                                                <Col>
                                                    <div id="property_list_results" className="property-card-list property_list_results">
                                                        <CustomInfiniteHits location={this.props.location} userObjects={this.props.userObjects} />

                                                        {/* Properties For Sale Residential Start */}
                                                        <CustomNoStats location={this.props.fullpathname} status={'For Sale'} department={'international'} />
                                                        {/* Properties For Sale Residential End */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>

                                    <div className="map-view-wrap">
                                        {(!isMobile) &&
                                            <MapResultsDesktop ptype="mySaleProp" defaultLatLng={{
                                                lat: 37.908028,
                                                lng: -0.745323
                                              }}/>
                                        }
                                        {(isMobile) &&
                                            <MapResultsDevice />
                                        }
                                        {/* <MapResults /> */}
                                        {/* <img src={mapImg} alt="" className="map_img" />
                                    <div className="propertycart">
                                        <div className="propertycart_img">
                                            <img src={propertycartImg} alt="" />
                                        </div>
                                        <div className="propertycart-info">
                                            <strong>£ 520,676</strong>
                                            <h2>3 bedroom apartment for sale</h2>
                                            <p>Victoria residence, Crown Street, Manchester, M15</p>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </InstantSearch>
                            {/* <SubscribeBlock /> */}

                            <div className="breadcrumb-block text-center" >
                                <Container>
                                    <Row>
                                        <Col lg={12}>
                                            <Breadcrumb>
                                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                                <Breadcrumb.Item active>Sales</Breadcrumb.Item>
                                            </Breadcrumb>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>

                        <Footer popularsearch="searchresults" searchParams={searchParams} />
                    </div>
                </UserObjectStoreProvider>
            </>
        )
    }
}

const PropertySales = (props) => {
    const [userObjects, setUserObjects] = useState({});
    const { state: authState, services: authServices } = useAuthState();
    const isAuthenticated = authServices.isAuthenticated();

    useEffect(() => {
        if (isAuthenticated) {
            const getUserObjects = async () => {
                try {
                    const userObj = await getUserobjectData()
                    setUserObjects(userObj.data.data)
                } catch (e) {
                    console.log("e:", e)
                }
            }
            getUserObjects()
        }
    }, []);

    return (
        <UserObjectStoreProvider>
            <PropertySalesList {...props} userObjects={userObjects} />
        </UserObjectStoreProvider>
    )
}

export default PropertySales
